import {
	ContainerPersonalizationStoryblok,
	GlobalInfoBannerStoryblok,
	GlobalMenuV3Storyblok,
	GlobalStoryblok,
	SearchPageStoryblok,
	SubMenuV2Storyblok,
} from '@/components';
import { StoryblokBridgeProvider, useStoryblokBridge } from '@/contexts/bridge';
import { ConfigProvider, useServerPageConfig } from '@/contexts/config';
import { GlobalMenuProvider } from '@/contexts/menu/GlobalMenuProvider';
import { SubMenuProvider } from '@/contexts/menu/SubMenuProvider';
import { AddSearchProviderV2 } from '@/contexts/search/AddSearchProviderV2';
import { SeoProvider } from '@/contexts/seo/SeoProvider';
import { generateStoryMeta } from '@/contexts/seo/meta';
import { InvalidContentWarning } from '@/contexts/warning';
import { ServerPageConfig } from '@/server/config';
import { Env } from '@/server/config/types';
import { getComponentsFromStory } from '@/server/storyblok/getComponentsFromStory';
import { isPiwikDisabled } from '@/trackers/piwik';
import { ISbStoryData } from '@/types/storyblok';
import { cls, initLocales, readCookie } from '@/utils';
import { handleCustomDimension } from '@/utils/piwik';
import { resolveRelations } from '@/utils/storyblok/resolveRelations';
import PiwikProProvider, { usePiwikPro } from '@piwikpro/next-piwik-pro';
import { getCookie } from 'cookies-next';
import { FAQPageJsonLdProps, NextSeo, NextSeoProps } from 'next-seo';
import { useRouter } from 'next/router';
import { CSSProperties, useEffect, useState } from 'react';
import { GlobalInfoBanner, LanguageToggle } from '.';
import DynamicComponent from '../DynamicComponent';
import { SubMenuV2 } from '../Menu';
import { GlobalMenuV3 } from '../Menu';
import { GlobalInfoBannerV2 } from './GlobalInfoBannerV2';
import { GlobalHeader } from './GlobalMenu/GlobalHeader';
import { GlobalPopUp, GlobalPopUpProps } from './GlobalPopUp';
import styles from './Layout.module.scss';
import { PersonalizationToggle } from './PersonalizationToggle';

interface Stories {
	globalStory: ISbStoryData<GlobalStoryblok>;
	story: ISbStoryData;
	popUpStories?: GlobalPopUpProps[];
}

interface InnerProps extends Stories {
	config: ServerPageConfig;
	meta?: Record<string, unknown>;
	pageSpecificMeta?: NextSeoProps;
}

export interface DynamicMeta {
	title?: string;
	description?: string;
	noIndex?: boolean;
	canonical?: string | undefined;
	image?: { path: string | undefined | null; alt: string | undefined | null } | null;
}

interface Props extends InnerProps {
	locale: string;
	dynamicMeta?: DynamicMeta;
	faqMeta?: FAQPageJsonLdProps['mainEntity'];
}

const getBloks = ({
	globalStory,
	story,
	popUpStories,
}: Stories): [
	ISbStoryData,
	ISbStoryData | null,
	ISbStoryData,
	GlobalPopUpProps[] | null,
	ISbStoryData<GlobalInfoBannerStoryblok> | null,
] => {
	let menu = globalStory.content.menu as unknown as ISbStoryData;
	let main: ISbStoryData | null = story;
	let footer = globalStory.content.footer as unknown as ISbStoryData;
	const popUpForms = popUpStories ?? null;
	const infoBanner = globalStory.content.infoBanner as unknown as ISbStoryData<GlobalInfoBannerStoryblok>;

	if (story.full_slug === menu?.full_slug) {
		menu = story;
		main = null;
	} else if (story.full_slug === footer?.full_slug) {
		footer = story;
		main = null;
	}

	return [menu, main, footer, popUpForms, infoBanner];
};

export function Layout({ config, locale, meta = {}, faqMeta, ...props }: Props) {
	const { env, externalDeployment } = useServerPageConfig();
	const pageSpecificMeta: NextSeoProps = generateStoryMeta(props.story, props?.dynamicMeta);

	const slug = props.story.full_slug;
	if (slug.startsWith('developer')) locale = 'en';

	const isExternalDeploymentProd = env === Env.Prod && externalDeployment;

	const piwikContainerId = isExternalDeploymentProd
		? '8c5cd131-8d36-48dd-9d33-9b3802d223fc'
		: 'd3954971-579d-43ba-9edc-6a7578bd1f53';

	const content = (
		<LayoutInner
			{...props}
			config={{ ...config, locale, faqMeta }}
			meta={{ ...meta, locale: locale }}
			pageSpecificMeta={pageSpecificMeta}
		/>
	);

	return (
		<StoryblokBridgeProvider resolveRelations={resolveRelations}>
			<ConfigProvider config={{ ...config, locale: locale, faqMeta }}>
				<NextSeo {...pageSpecificMeta} />
				{isPiwikDisabled ? (
					content
				) : (
					<PiwikProProvider containerUrl="https://fortnox.containers.piwik.pro" containerId={piwikContainerId}>
						{content}
					</PiwikProProvider>
				)}
			</ConfigProvider>
		</StoryblokBridgeProvider>
	);
}

const hasPersonalization = (story: ISbStoryData) => {
	const personalizationBloks = getComponentsFromStory(story, 'ContainerPersonalization');
	const array = [];
	personalizationBloks.forEach((v) => array.push(v));
	return array.length > 0;
};

const getPersonalizedSegments = (story: ISbStoryData) => {
	const personalizationBloks = getComponentsFromStory(story, 'ContainerPersonalization');
	const array: ContainerPersonalizationStoryblok[] = [];
	personalizationBloks.forEach((v) => array.push(v as any));
	const segments = array
		.map((blok) => {
			if (blok.header) return blok.header.map((header) => header.segments);
			if (blok.sections) return blok.sections.map((section) => section.segments);
		})
		.flat(2);
	return [...new Set(segments)];
};

const logVariant = (testName: string, variant: string | null) => {
	if (!variant) {
		return;
	}

	handleCustomDimension({
		customDimensionId: 5,
		customDimensionValue: testName,
	});
	handleCustomDimension({
		customDimensionId: 6,
		customDimensionValue: variant!,
	});
};

function LayoutInner({ config, meta, ...props }: InnerProps) {
	const [story, globalStory] = useStoryblokBridge([props.story, props.globalStory]);
	const popUpStories = useStoryblokBridge(props.popUpStories);
	const [menu, main, footer, popUpForms, infoBanner] = getBloks({ globalStory, story, popUpStories });
	const [scrollbarWidth, setScrollbarWidth] = useState<number | null>(null);
	const router = useRouter();
	const { DataLayer } = usePiwikPro();
	const subMenu = story?.content?.subMenu;
	const hasSubMenuV2 = subMenu?.content?.component === 'SubMenuV2';
	const menuVersionV3 = menu?.content?.component === 'GlobalMenuV3';

	useEffect(() => {
		if (!scrollbarWidth || !window) {
			setScrollbarWidth(window.innerWidth - document.body.clientWidth);
		}
	}, [scrollbarWidth]);

	const logAbTestVariant = () => {
		if (config.preview) {
			return;
		}
		const isSkaffaFortnoxPage = router.asPath == '/skaffa-fortnox' || router.asPath == '/skaffa-fortnox/2';

		if (isSkaffaFortnoxPage) {
			const variant = getCookie('ab_test_skaffa_fortnox')?.toString() ?? null;
			logVariant('Skaffa Fortnox A/B test', variant);
		}
	};

	const style = {
		'--global-menu-offset': menuVersionV3 ? '1rem' : '0',
		'--scrollbar-width': `${scrollbarWidth ?? 0}px`,
		...(menuVersionV3 ? { '--global-menu-height': 'var(--global-menu-height-new)' } : {}),
	} as CSSProperties;

	getPersonalizedSegments(story);
	useEffect(() => {
		const customerType = readCookie('customerType') ?? undefined;
		const activeSegments = getPersonalizedSegments(story);
		DataLayer.push({
			event: 'virtualPageView',
			virtualPageTitle: props.pageSpecificMeta?.title,
			virtualPageUrl: window.location.href,
			pageType: story.content.component,
			customerType,
			personalizedExperience: hasPersonalization(story) && activeSegments.includes(customerType) ? 'yes' : 'no',
		});
		logAbTestVariant();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [router.asPath]);

	const storyMeta = {
		tags: story.tag_list,
		published_at: story.published_at,
		first_published_at: story.first_published_at,
		full_slug: story.full_slug,
	};

	const searchPageStory =
		main?.content.component === 'SearchPage' ? (main as ISbStoryData<SearchPageStoryblok>) : undefined;

	const stories = [story, globalStory];

	const locale = meta?.locale ? (meta.locale as string) : 'sv';

	initLocales(locale);

	return (
		<div className={cls(styles.container, menuVersionV3 && styles.menuV3)} style={style}>
			{hasPersonalization(story) && <PersonalizationToggle />}

			<GlobalMenuProvider menuStory={menu} infoBannerStory={infoBanner}>
				{menuVersionV3 ? (
					<AddSearchProviderV2>
						{menu?.content && (
							<GlobalMenuV3
								{...GlobalMenuV3.blokProps({ blok: menu.content as GlobalMenuV3Storyblok })}
								scrollbarWidth={scrollbarWidth}
							/>
						)}
						{infoBanner && <GlobalInfoBannerV2 {...infoBanner.content} />}
					</AddSearchProviderV2>
				) : (
					<>
						{!menuVersionV3 && infoBanner && <GlobalInfoBanner {...infoBanner.content} />}
						<GlobalHeader
							blok={menu?.content as any}
							searchPageStory={searchPageStory}
							withInfoBanner={infoBanner ? true : false}
						/>
					</>
				)}

				{hasSubMenuV2 && <SubMenuV2 subMenu={subMenu as ISbStoryData<SubMenuV2Storyblok>} />}

				{popUpForms?.map(
					(item) =>
						item?.urls?.find((url) => {
							return (
								(url.url?.cached_url === router.asPath && url.url.linktype === 'url') ||
								(url.url?.cached_url?.replace(/\/$/, '') === router.asPath.substring(1) && url.url.linktype === 'story')
							);
						}) && <GlobalPopUp {...item} key={item._uid} />,
				)}
				{main?.content && (
					<SubMenuProvider storyContent={story.content}>
						<SeoProvider config={config} story={main as any}>
							{config.englishVersion && (
								<LanguageToggle
									breadcrumbTheme={config.breadcrumbsTheme}
									story={{ ...story, lang: meta?.locale as string }}
									withInfoBanner={infoBanner ? true : false}
								/>
							)}

							{/* Override the <html lang="sv"> to tell that the content inside this div is in english */}
							<div lang={(story as any).lang === 'en' ? 'en' : undefined}>
								<DynamicComponent blok={main?.content} meta={{ ...storyMeta, ...meta, locale: meta?.locale }} />
							</div>
						</SeoProvider>
					</SubMenuProvider>
				)}

				<DynamicComponent blok={footer?.content} />

				{process.env.NEXT_PUBLIC_PREVIEW_ENABLED && <InvalidContentWarning stories={stories} />}
			</GlobalMenuProvider>
		</div>
	);
}
