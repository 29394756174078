import { useServerPageConfig } from '@/contexts/config';
import { Datasource } from '@/types';
import { fetchApi } from '@/utils/fetchApi';
import { useInStoryblokEditor } from 'hooks/useInStoryblokEditor';
import * as React from 'react';
import { useEffect, useState } from 'react';
import styles from './PersonalizationToggle.module.scss';

/**
 * Only visible inside storyblok editor
 */
export const PersonalizationToggle = () => {
	const { setCustomerType, externalDeployment } = useServerPageConfig();

	const [options, setOptions] = useState<Datasource[]>();
	useEffect(() => {
		if (externalDeployment) return;
		const fetchSegmentOptions = async () => {
			const result = await fetchApi('datasources-v2', { slug: 'personalization-segments' }).then((r: any) => r);
			setOptions(result.body.data);
		};

		fetchSegmentOptions();
	}, [externalDeployment]);

	return useInStoryblokEditor() && options?.length ? (
		<div className={styles.container}>
			<select
				title="customerType"
				onChange={(e) => {
					setCustomerType(e.target.value);
				}}
				defaultValue={'Välj segment'}
			>
				{options?.map((o) => (
					<option key={o.id} value={o.value}>
						{o.name}
					</option>
				))}
				<option value="">Välj segment</option>
			</select>
		</div>
	) : null;
};
