import type { ImageAssetProps } from '@/metabloks';
import { ISbComponentType } from '@/types/storyblok';
import { findBlok } from '@/utils/story';

export interface HeaderData {
	h1Part1: string;
	h1Part2?: string;
	text?: string;
	shortText?: Record<string, any>;
	image?: ImageAssetProps;
}

const headerBloks = [
	'BookkeepingPost',
	'ErrorPage',
	'Header',
	'HeaderImage',
	'HeaderImageLow',
	'HeaderArticle',
	'HeaderConversion',
	'HeaderNewsroom',
	'HeaderStory',
	'HeaderProduct',
	'SearchPage',
	'WordPost',
	'HeaderSupport',
	'HeaderMini',
];

export const getHeaderData = (story: any): HeaderData => {
	let headerBlok = story.content?.header?.[0];
	if (story.content.component === 'ArticlePage')
		return { h1Part1: story.content.title, text: story.content.intro, image: story.content.image };
	if (headerBlok?.component === 'ContainerPersonalization') headerBlok = headerBlok.default[0];
	if (headerBlok) return headerBlok;
	[headerBlok] = findBlok(story as any, headerBloks) as unknown as ISbComponentType<any>[];
	return headerBlok as unknown as HeaderData;
};
