export const resolveRelations = [
	'AccordionFaq.panels',
	'ArticleGroupSmall.posts',
	'ArticleMiniGroup.articles',
	'BlogLibraryPage.contact',
	'BlogPost.contact',
	'BlogPost.sidebar',
	'BlogPost.subMenu',
	'BookkeepingPost.contact',
	'BookkeepingPost.sidebar',
	'CampaignPage.subMenu',
	'CardArticleGroup.articles',
	'CardContact.content',
	'CardFiveUpsalesForm.form',
	'CardLeads.form',
	'CompanyGuidePost.contact',
	'CompanyGuidePost.sidebar',
	'CompanyGuidePost.subMenu',
	'CompanyItemGroup.item',
	'ComparisonTableProduct.product', // TODO: remove when fixed in prod
	'CoverageRateCalculator.calculationText', // TODO: remove
	'EducationApplicationPage.contact',
	'EducationCategoryPage.contact',
	'EducationPage.contact',
	'EmployeeCalculator.calculationText', // TODO: remove
	'EquityCalculator.calculationText', // TODO: remove
	'Faq.form',
	'FileUploadForm.form', // TODO: remove
	'Global.footer',
	'Global.infoBanner',
	'Global.menu',
	'GlobalPopUp.form',
	'IntegrationLibraryPage.contact',
	'JobAdvertisementPage.contact',
	'LandingPage.contact',
	'LandingPage.subMenu',
	'NavigationPageBlog.contact',
	'NavigationPageCareer.careerContact',
	'NavigationPageCareer.contact',
	'NavigationPageCareer.subMenu',
	'NavigationPageCompanyGuide.articles',
	'NavigationPageCompanyGuide.contact',
	'NavigationPageCompanyGuide.subMenu',
	'NavigationPageDictionary.contact',
	'NavigationPageDictionary.subMenu',
	'NavigationPageEducation.contact',
	'NavigationPageEducation.globalTextAndLinkSection',
	'NavigationPageEducation.subMenu',
	'NavigationPageFindAccountants.contact',
	'NavigationPageFindAccountants.subMenu',
	'NavigationPageIntegrations.contact',
	'NavigationPageIntegrations.subMenu',
	'NavigationPageNewsroom.contact',
	'NavigationPageNewsroom.subMenu',
	'NewsLibraryPage.contact',
	'NewsPost.contact',
	'PackageContactCard.form',
	'PageDeprecated.contact',
	'PopUpButton.form',
	'PressReleasePost.contact',
	'PriceCardLarge.extraProducts',
	'PriceCardLarge.product',
	'PriceCardLargeMainProduct.product',
	'PriceCardProductListItem.product',
	'PriceCardV2.antiUsps',
	'PriceCardV2.product',
	'PriceCardV2.usps',
	'PriceContactCard.form',
	'PriceListItem.product',
	'ProductCatalogPackageItem.products',
	'ProductPage.mainProduct',
	'ProfileGroup.items',
	'ProfitMarginCalculator.calculationText', // TODO: remove
	'SalaryCalculator.calculationText', // TODO: remove
	'SalaryResult.calculationText', // TODO: remove
	'SectionCalendar.calendar',
	'SectionComparisonTable.segmentDescriptions',
	'SectionConsultant.consultants',
	'SectionContactForm.form',
	'SectionFinancialKeyFigures.figures',
	'SectionFinancialKeyFigures.figuresWithEquation',
	'SectionFortnoxPepole.items',
	'SectionJobList.contact',
	'SectionLogosSidescroll.logos',
	'SectionProductNews.product',
	'SectionProfile.cardSupportProfile',
	'SectionQuote.quote',
	'SectionTestimonial.articleTestimonial',
	'SectionTestimonial.testimonial',
	'SectionTestimonial.usps',
	'SectionTestimonialLogos.testimonials',
	'SectionTextLink.content', // TODO: remove
	'SectionUpsalesForm.form',
	'SectionUsp.globalUspGroup',
	'SectionZendeskForm.form',
	'SinglePage.contact',
	'SinglePage.subMenu',
	'StoryPage.contact',
	'StoryPage.subMenu',
	'StoryPeopleGroup.items', // TODO: remove
	'StoryPost.contact',
	'StoryPost.subMenu',
	'StoryTestimonial.items',
	'SupportPage.contact',
	'TestimonialLargeGroup.testimonial',
	'UpsalesForm.inputFields',
	'UspMediumProduct.product', // TODO: remove. Still used in prod
	'VatCalculator.calculationText', // TODO: remove
	'WordPost.contact',
	'WordPost.sidebar',
];
