import { ContainerFluid, Icon, LinkAnchor, Section } from '@/atoms';
import { useSubMenu } from '@/contexts/menu/SubMenuProvider';
import { useMeta } from '@/contexts/seo/SeoProvider';
import { ISbStoryData } from '@/types/storyblok';
import { cls } from '@/utils';
import Image from 'next/image';
import styles from './Breadcrumbs.module.scss';

interface Props {
	story: ISbStoryData;
	breadcrumbTheme?: string;
	homePath?: string;
}

export const Breadcrumbs: React.FC<Props> = ({ story, breadcrumbTheme, homePath = '/' }) => {
	const { hasSubMenu } = useSubMenu();
	const meta = useMeta();
	const hasSideNav = story.name === 'Nyhetsrum';
	const theme = hasSubMenu ? 'light' : (breadcrumbTheme as 'light' | 'dark');
	const iconSrc = theme === 'dark' ? 'home-white' : 'home-darkgreen';
	const noBreadcrumbPages = ['loggat-ut'];

	if (!meta.breadcrumbs?.length || noBreadcrumbPages.includes(story.slug)) {
		return null;
	}

	const breadcrumbs = [...meta.breadcrumbs];
	const current = breadcrumbs.pop()!;

	return (
		<ContainerFluid marginBottom="none" noBackgroundColor>
			<Section padding={{ base: 'none' }}>
				<nav
					className={cls(styles.container, { [styles.hasSideNav]: hasSideNav }, hasSubMenu && styles.hasSubMenu)}
					data-testid="breadcrumbs"
				>
					<ol className={styles.breadcrumbs}>
						<li className={styles.breadcrumb} key="home">
							<LinkAnchor
								href={homePath}
								className={cls(styles.breadcrumbLink, styles.homeLink)}
								data-testid="breadcrumbs-home-link"
							>
								<Image
									src={`/icons/${iconSrc}.svg`}
									alt="home icon"
									width={20}
									height={22}
									data-testid="breadcrumbs-home-link-icon"
								/>
							</LinkAnchor>
						</li>
						{breadcrumbs.map((breadcrumb, i: number) => (
							<li key={i} className={styles.breadcrumb}>
								<LinkAnchor
									href={breadcrumb.item}
									className={cls(styles.breadcrumbLink)}
									data-testid={`breadcrumbs-link-${i + 1}`}
								>
									<Icon className={styles.chevron} name="chevron-right" size="xs" />
									{breadcrumb.name}
								</LinkAnchor>
							</li>
						))}
						<li className={styles.breadcrumb}>
							<span className={cls(styles.breadcrumbLink, styles.currentPage)}>
								<Icon className={styles.chevron} name="chevron-right" size="xs" />
								{current.name}
							</span>
						</li>
					</ol>
				</nav>
			</Section>
		</ContainerFluid>
	);
};
